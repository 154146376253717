<template>
    <div class="chapter-read">
        <div class="navigation">
            <button class="top-button" @click="goBack">&lt; Back</button>
            <button class="top-button" @click="goHome">Home</button>
            <button class="top-button" @click="goBookDetail">Book Details</button>
        </div>
        <div v-if="chapterInfo.create_ts > 0">
            <div class="content">
                <div class="chapter-title">{{ chapterInfo.title }}</div>
                <div class="publish-date">{{ formatDate(chapterInfo.create_ts * 1000) }}
                </div>

                <div class="book-meta-panel" v-bind:class="{ 'expanded': isMetaExpanded }">
                    <div class="book-meta">
                        <span class="book-category" v-for="category in chapterInfo.categories" :key="category">{{
                category
            }}</span>
                    </div>
                    <div class="book-meta">
                        <span class="book-tag" v-for="tag in chapterInfo.tags" :key="tag">{{ tag }}</span>
                    </div>
                    <button class="meta-toggle-button" @click="toggleMetaDisplay">
                        {{ isMetaExpanded ? 'Show Less' : 'Show More' }}
                    </button>
                </div>



                <div class="chapter-content">
                    <!-- 章节内容 -->
                    <p class="tag" v-for="(row, idx) in chapterInfo.rows" :key="idx">{{ row }}</p>

                </div>
                <div class="chapter-navigation sticky-buttons">
                    <div class="previous" v-if="chapterInfo.previous_id > 0"
                        @click="onPreviousClick(chapterInfo.previous_id)">Previous</div>
                    <div v-else>
                    </div>
                    <div class="next" v-if="chapterInfo.next_id > 0" @click="onNextClick(chapterInfo.next_id)">Next
                    </div>
                    <div v-else>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>loading</div>


        <footer class="footer">
            © 2024 fictionpool.com All Rights Reserved
            <!-- <a href="mailto:contact@gostarfavor.com">contact@gostarfavor.com</a> -->
        </footer>
    </div>
</template>

<script>

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import dayjs from 'dayjs';

export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const chapterId = ref(0);
        const bookId = ref(0);
        const chapterInfo = ref({});
        const isMetaExpanded = ref(false);
        const fetchChapter = async (id) => {
            axios.post('https://fictionpool.com/api/chapter', {
                chapterID: id + ""
            })
                .then(response => {
                    // console.log(response.data);
                    chapterInfo.value = response.data;
                })
                .catch(error => {
                    console.log("error:" + error.message)
                });
        }

        onMounted(() => {
            chapterId.value = route.params.chapterId;
            bookId.value = route.params.bookId;
            fetchChapter(chapterId.value);
        });

        const goBack = () => {
            router.go(-1);
        };
        const formatDate = (ts) => {
            return dayjs(ts).format('YYYY-MM-DD'); // 格式化为 YYYY-MM-DD 格式
        }
        const onPreviousClick = (id) => {
            chapterId.value = id;
            router.push({ name: 'ChapterRead', params: { bookId: bookId.value, chapterId: id } });
            // fetchChapter(id)
        }
        const onNextClick = (id) => {
            chapterId.value = id;
            router.push({ name: 'ChapterRead', params: { bookId: bookId.value, chapterId: id } });
            // fetchChapter(id)
        }
        const goHome = () => {
            router.push({ name: 'Home' });
        }
        const goBookDetail = () => {
            router.push({ name: 'BookDetail', params: { id: bookId.value } });
        }
        const toggleMetaDisplay = () => {
            isMetaExpanded.value = !isMetaExpanded.value;
        };
        return {
            goBack,
            formatDate,
            onPreviousClick,
            onNextClick,
            goHome,
            goBookDetail,
            toggleMetaDisplay,
            chapterId,
            bookId,
            chapterInfo,
            isMetaExpanded,
        };
    },
};
</script>

<style>
.chapter-read {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    max-width: 800px;
    /* 根据您的布局需求调整 */
    margin: auto;
    padding: 1rem;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
}

/* .back-button {
    font-size: 1rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
} */

.content {
    padding: 1rem 0;
}

.chapter-title {
    text-align: left;
    font-size: 18px;
}

.book-meta {
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
    line-height: 20px;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
}

.book-category {
    background-color: rgb(255, 199, 17);
    border-radius: 4px;
    padding: 2px 6px;
    margin-right: 10px;
    flex: 0 0 auto;
    /* 防止 span 缩小 */
    margin: 2px;
    /* 可选，为了更好的视觉效果 */
}

.book-tag {
    background-color: #eef;
    border-radius: 4px;
    padding: 2px 6px;
    margin-right: 10px;
    flex: 0 0 auto;
    /* 防止 span 缩小 */
    margin: 2px;
    /* 可选，为了更好的视觉效果 */
}

.publish-date {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 0.3rem;
    text-align: left;
}

.chapter-content {
    margin-bottom: 1rem;
}

.chapter-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.previous,
.next {
    background-color: #f0ad4e;
    border: none;
    padding: 0.3rem 1rem;
    cursor: pointer;
    color: white;
    border-radius: 0.25rem;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}

.footer {
    text-align: center;
    border-top: 1px solid #ccc;
    padding-top: 1rem;
}

.footer a {
    color: #007bff;
    text-decoration: none;
}

.book-meta-panel {
    overflow: hidden;
    max-height: 1.6em;
    /* 假设每行高度为1em，这里根据实际情况调整 */
    transition: all 0.3s ease;
    position: relative;
    /* 添加这一行，为绝对定位提供基准 */
    border: 1px solid #ccc;
    /* 圆角边框 */
    border-radius: 10px;
    /* 设置圆角大小 */
    padding: 10px;
    /* 添加内边距以避免内容紧贴边框 */
}

.book-meta-panel.expanded {
    max-height: none;
    /* 展开时移除高度限制 */
}

.meta-toggle-button {
    background-color: transparent;
    /* 背景透明 */
    color: #ccc;
    /* 根据需要调整文字颜色 */
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    position: absolute;
    right: 3px;
    /* 可能需要调整，确保与圆角对齐 */
    bottom: 5px;
    /* 可能需要调整，确保与圆角对齐 */
    font-weight: bold;
    border-radius: 0.25rem;
    /* 如果需要，给按钮添加圆角 */
}

.sticky-buttons {
    position: sticky;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    /* Semi-transparent background */
    padding: 10px;
    z-index: 10;
    /* Make sure the buttons are above other content */
}

.top-button {
    /* display: flex;
    flex-direction: column; */
    align-items: center;
    /* flex-grow: 1; */
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 3px;
    margin-right: 3px;
    cursor: pointer;
    background:linear-gradient(90deg, #7398ff, #4f77fb);
    transition: background-color 0.3s;
    color: white;
    border: 1px solid #ccc;
    /* 圆角边框 */
    border-radius: 6px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
}
</style>