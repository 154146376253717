import { createRouter, createWebHashHistory } from 'vue-router';
import BookDetail from '../components/BookDetail.vue';
import ChapterRead from '../components/ChapterRead.vue';
import HomeView from '../components/HomeView.vue'; // 假设你有一个主页组件

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { title: 'fictionpool.com | Free Novel Pool' },
  },
  {
    path: '/book/:id', // 使用动态路由匹配以便传递书籍 ID
    name: 'BookDetail',
    component: BookDetail,
    props: true, // 允许组件通过 props 接收路由参数
    meta: { title: 'fictionpool.com | Free Novel' },
  },
  {
    path: '/book/:bookId/chapter/:chapterId', // 同样使用动态路由匹配以传递书籍和章节的 ID
    name: 'ChapterRead',
    component: ChapterRead,
    props: true,
    meta: { title: 'fictionpool.com | Free Novel' },
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),// createWebHistory(process.env.BASE_URL),
  routes,
});

// 导航守卫，每次路由变化后设置网页标题
router.afterEach((to, from) => {
  // if (from.meta) {
  //   var a = from.hash;
  // }
  if (to.meta && to.meta.title) {
    document.title = from.meta.title;
    document.title = to.meta.title;
  }
});
export default router;
