// LazyLoad.js
export const LazyLoad = {
    mounted(el, binding) {
      function loadImage() {
        el.src = binding.value;// `https://light-novel.us/${binding.value}`;
      }
  
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            loadImage();
            observer.unobserve(el);
          }
        });
      }, {
        root: null,
        threshold: 0.01
      });
  
      observer.observe(el);
    }
  }
  