<template>
  <div id="app">
    <!-- 顶部导航栏 -->
    <div class="top-nav">
      <input class="search-box" type="text" v-model="searchQuery" placeholder="Search novel">
      <button class="refresh-button" @click="onSearch">Search</button>
    </div>
    <div class="book-list">
      <van-pull-refresh v-model="isLoading" pulling-text="Pull down to refresh"
        loosing-text="Release to refresh immediately" loading-text="Loading..." @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" loading-text="loading..." finished-text="No more~"
          @load="onLoad">
          <div v-for="book in books" :key="book.id" class="book-item" @click="onBookClick(book)">
            <img v-lazy="book.cover_url" class="book-cover" :alt="book.title">
            <div class="book-info">
              <h3>{{ book.title }}</h3>
              <div class="book-meta">
                <span v-for="category in book.categories" :key="category">
                  <span class="book-category">{{ category }}</span>
                </span>
                |
                <span v-for="tag in book.tags" :key="tag">
                  <span class="book-tags">{{ tag }}</span>&nbsp;
                </span>

              </div>
              <div class="book-update-time">
                Last Update: {{ formatDate(book.last_update * 1000) }}
              </div>
              <p>{{ book.brief }}</p>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div v-if="searchResultEmpty" class="loading">No novels can be
      found.</div>

    <!-- 底部导航栏 -->
    <!-- <div class="bottom-tab-bar">
      <div class="tab-button active">
        <i class="fas fa-book"></i>
        <span>Library</span>
      </div>

      <div class="tab-button">
        <i class="fas fa-user"></i>
        <span>Me</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { PullRefresh, List } from 'vant';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import dayjs from 'dayjs';

export default {
  name: 'App',
  components: {
    [PullRefresh.name]: PullRefresh,
    [List.name]: List
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const books = ref([]);
    const page = ref(0);
    const totalPage = ref(-1);

    const isFetching = ref(false);
    const loading = ref(false);
    const finished = ref(false);
    const isLoading = ref(false);
    const searchQuery = ref("");
    const searchResultEmpty = ref(false)
    // searchQuery.value = route.query.search;
    // 监听 URL 的查询参数变化，初始化时填充输入框
    watch(() => route.query.search, (newSearch) => {
      searchQuery.value = newSearch || '';
    }, { immediate: true });


    const fetchData = async (refresh) => {
      if (isFetching.value == true) return;
      isFetching.value = true;
      if (refresh) {
        page.value = 1;
      } else {
        page.value = page.value + 1;
      }
      axios.post('https://fictionpool.com/api/books?page=' + page.value + '&search=' + searchQuery.value)
        .then(response => {
          // console.log(response.data.books)
          if (page.value == 1) {
            books.value = response.data.books;
          } else {
            books.value = [...books.value, ...response.data.books];
          }

          if (searchQuery.value.length > 0) {
            if (books.value.length > 0) {
              searchResultEmpty.value = false;
            } else {
              searchResultEmpty.value = true;
            }
          } else {
            searchResultEmpty.value = false;
          }
          totalPage.value = response.data.total
          console.log("totalPage.value:" + totalPage.value);
          if (page.value >= totalPage.value && totalPage.value > 0) {
            console.log("page.value >= totalPage.value && totalPage.value > 0");
            finished.value = true;
          }
        })
        .catch(error => {
          console.log("error:" + error.message)
        }).finally(() => {
          console.log("finally loading.value:" + loading.value + " isLoading.value:" + isLoading.value);
          isFetching.value = false; // 请求完成后释放锁
          if (isLoading.value == true){
            isLoading.value = false; // 更新加载状态
            console.log("set isLoading:" + isLoading.value)
          }
          if (loading.value == true){
            loading.value = false;
            console.log("set loading:" + loading.value)
          }
        });
    }

    const formatDate = (ts) => {
      return dayjs(ts).format('YYYY-MM-DD'); // 格式化为 YYYY-MM-DD 格式
    }

    const onLoad = async () => {
      console.log("loading.value:" + loading.value + " finished.value:" + finished.value);
      console.log("page.value:" + page.value + " totalPage.value:" + totalPage.value);

      if (loading.value || finished.value || (page.value == totalPage.value && totalPage.value > 0)) return;
      console.log("page.value:" + page.value + " totalPage.value:" + totalPage.value);
      loading.value = true;
      try {
        await fetchData(false);
      } catch (err) {
        // 错误处理
        console.log("onLoad err:" + err);
      } 
    };

    const onRefresh = async () => {
      isLoading.value = true;
      try {
        books.value = []; // 重置书籍列表
        finished.value = false; // 重置加载状态
        await fetchData(true); // 重新加载数据
      } catch (err) {
        // 错误处理
        console.log("onRefresh err:" + err);
      } 
    };
    const onSearch = async () => {
      console.log("onSearch:"+searchQuery.value);
      router.push({ query: { ...route.query, search: searchQuery.value } });
      // isLoading.value = true;
      // try {
      //   await fetchData(true);
      // } catch (error) {
      //   console.log("onSearch err:" + error);
      // } finally {
      //   isLoading.value = false;
      // }
    };

    const onBookClick = (book) => {

      console.log("Clicked book: ", book.title);
      router.push({ name: 'BookDetail', params: { id: book.id } });
    };
    onMounted(() => {
      // onLoad();

    }); // 注意这里的改动
    return {
      books,
      loading,
      finished,
      isLoading,
      isFetching,
      searchQuery,
      searchResultEmpty,
      onLoad,
      onRefresh,
      onSearch,
      onBookClick,
      formatDate
    };
  }
};
</script>

<style>
/* 这里添加你的CSS样式 */
/* 顶部导航栏样式 */
.top-nav {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.search-box {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  /* 与刷新按钮之间的间距 */
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.refresh-button {
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

/* 底部导航栏样式 */
.bottom-tab-bar {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  padding: 10px 0;
}

.tab-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-button i {
  font-size: 20px;
  /* 可以根据实际情况调整图标大小 */
  margin-bottom: 4px;
}

.tab-button span {
  font-size: 12px;
  color: #555;
  /* 文字颜色也可以根据需要调整 */
}

/* 激活状态的样式 */
.tab-button.active {
  color: #d0d0d0;
  /* 可以根据实际设计调整激活状态的颜色 */
}

/* 鼠标悬停按钮时的样式 */
.tab-button:hover {
  background-color: #eaeaea;
}

.book-list {
  padding: 15px;
  overflow: auto;
  height: calc(100vh - 120px);
  /* 减去顶部和底部导航栏的高度 */
  position: relative;
  margin-top: 10px;
  /* margin-bottom: 56px; */
}

.book-item {
  display: flex;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  cursor: pointer;
}

.book-cover {
  width: 100px;
  height: 150px;
  background-color: #ddd;
  margin-right: 15px;
  flex-shrink: 0;
}

.book-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* 确保内容在主轴（垂直方向）上靠顶对齐 */
}

.book-info h3,
.book-meta,
.book-update-time,
p {
  text-align: left;
  /* 确保文本左对齐 */
}

.book-info h3 {
  margin: 0;
  font-size: 18px;
}

.book-info p {
  font-size: 14px;
}

.loading {
  text-align: center;
  padding: 10px;
}

.book-meta {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.book-category {
  margin-right: 10px;
}

.book-tags {
  background-color: #eef;
  border-radius: 4px;
  padding: 2px 6px;
}

.book-update-time {
  font-size: 12px;
  color: #999;
  margin-bottom: 5px;
}
</style>
