<template>
    <div class="book-detail">
        <div class="navigation">
            <div class="top-button" @click="goBack">&lt; Back</div>
        </div>
        <div v-if="cover_url != ''" class="book-info">
            <div class="book-base-info-panel">
                <img :src="cover_url" class="book-cover" :alt="book.title">
                <div class="book-base-info">
                    <div class="title">{{ book.title }}</div>
                    <div class="author">
                        <span class="author finished">{{ book.author }}</span>
                    </div>
                    <div class="chapter-update">{{ formatDate(book.last_update * 1000) }}</div>
                    <div class="chapter-update">{{ book.last_chapter }}</div>
                </div>
            </div>
            <div class="book-meta">
                <span v-for="category in categories" :key="category">
                    <span class="book-category">{{ category }}</span>
                </span>
            </div>
            <div class="book-meta">
                <span v-for="tag in tags" :key="tag">
                    <span class="book-tags">{{ tag }}</span>&nbsp;
                </span>
            </div>
            <div class="excerpt">
                <p>
                    {{ book.brief }}
                </p>
            </div>
            <div class="book-chapters">
                <div v-for="(volume, index) in volumes" :key="index" class="volume">
                    <h3 class="volume-title">{{ volume.Volume.volume_name }}</h3>
                    <ul class="chapter-list">
                        <li v-for="(chapter, cIndex) in volume.Chapters" :key="cIndex" @click="onChapterClick(chapter.id)">
                            {{ chapter.title }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="bottom-oprt">
                <button class="tab-button" v-if="showAddBtn">Add</button>
                <div class="tab-button" v-else></div>
                <div class="start-reading" @click="onChapterClick(firstChapter.id)">Start Reading</div>
            </div>
        </div>
        <div v-else class="loading">loading</div>
    </div>
</template>
<script>

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import dayjs from 'dayjs';

export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const book = ref({});
        const categories = ref({});
        const tags = ref({});
        const cover_url = ref("");
        const volumes = ref([]);
        const showAddBtn = ref(false);
        const firstChapter = ref({});
        const fetchBookDetails = async (id) => {
            axios.post('https://fictionpool.com/api/book_info', {
                bookID: id
            })
                .then(response => {
                    // console.log(response.data);
                    book.value = response.data.book;
                    console.log("url:" + book.value.cover_url);
                    cover_url.value = "/" + book.value.cover_url;// "https://light-novel.us/" + book.value.cover_url;
                    categories.value = response.data.categories;
                    tags.value = response.data.tags;
                    volumes.value = response.data.volumes;
                    firstChapter.value = response.data.firstChapter;
                })
                .catch(error => {
                    console.log("error:" + error.message)
                });
        }

        onMounted(() => {
            fetchBookDetails(route.params.id);
        });

        const goBack = () => {
            router.go(-1);
        };
        const formatDate = (ts) => {
            return dayjs(ts).format('YYYY-MM-DD'); // 格式化为 YYYY-MM-DD 格式
        }
        const onChapterClick = (chapterId) => {
            console.log("chapter id:"+chapterId);
            router.push({ name: 'ChapterRead', params: { bookId: book.value.id,chapterId:chapterId } });
        }
        
        return {
            book,
            goBack,
            formatDate,
            onChapterClick,
            categories,
            tags,
            cover_url,
            volumes,
            showAddBtn,
            firstChapter,
        };
    },
};
</script>

<style scoped>
.book-detail {
    font-family: 'Arial', sans-serif;
    /* background-color: #1e1e1e; */
    /* Adjust based on actual background */
}

.navigation {
    display: flex;
    align-items: center;
    /* padding: 1em; */
    background-color: #f8f8f8;
    width: 100%;
    border-top: 1px solid #e7e7e7;
    padding: 10px 0;
}

.back-button {
    background: none;
    border: none;
    color: #444;
    font-size: 18px;
    cursor: pointer;
}

.book-cover img {
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
}

.book-info {
    padding: 0 1em;
    margin-top: 10px;
}

.title {
    font-size: 20px;
    color: #00aaff;
    text-align: left;
}

.author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: #aaaaaa;
    /* Adjust based on actual color */
    margin-top: 3px;
    margin-bottom: 2px;
}

.stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    margin: 10px 0;
}

.excerpt p {
    font-size: 16px;
    color: #cccccc;
    /* Adjust based on actual color */
}

.chapter-update {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 6px 0;
}

/* .interactions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
} */

.bottom-oprt {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f8f8f8;
    border-top: 1px solid #e7e7e7;
    padding: 10px 0;
}

.tab-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.start-reading {
    /* display: flex;
    flex-direction: column; */
    align-items: center;
    /* flex-grow: 1; */
    padding-top: 10px;
    padding-bottom: 10px;
    width: 38%;
    cursor: pointer;
    background:linear-gradient(90deg, #7398ff, #4f77fb);
    transition: background-color 0.3s;
    color: white;
    border: 1px solid #ccc;
    /* 圆角边框 */
    border-radius: 6px;
    margin-right: 10px;
    font-size: 18px;
}

.book-meta {
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
    line-height: 20px;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
}

.book-category {
    background-color: rgb(255, 199, 17);
    border-radius: 4px;
    padding: 2px 6px;
    margin-right: 10px;
    flex: 0 0 auto; /* 防止 span 缩小 */
    margin: 2px; /* 可选，为了更好的视觉效果 */
}

.book-tags {
    background-color: #eef;
    border-radius: 4px;
    padding: 2px 6px;
    margin-right: 10px;
    flex: 0 0 auto; /* 防止 span 缩小 */
    margin: 2px; /* 可选，为了更好的视觉效果 */
}

.book-base-info-panel {
    display: flex;
    align-items: start;
    margin-bottom: 8px;
}

.book-base-info {
    text-align: left;
}


.book-chapters {
    margin: 20px 0;
    text-align: left;
    border-bottom: #cccccc 1px;
}

.volume {
    margin-bottom: 15px;
}

.volume-title {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.chapter-list {
    list-style-type: none;
    padding-left: 0;
}

.chapter-list li {
    font-size: 18px;
    color: #666;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
}

.chapter-list li:not(:first-child) {
    border-top: 1px solid #ccc;
}

/* 应用卡片样式 */
.book-base-info-panel, .book-chapters .volume {
    background-color: #fff;
    border-left: none; /* 去除左边框 */
    border-right: none; /* 去除右边框 */
    border-top: 1px solid #ddd; /* 保留顶部边框 */
    border-bottom: 1px solid #ddd; /* 保留底部边框 */
    border-radius: 10px; /* 如果需要保留圆角，可能需要调整 */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1), 0 -4px 8px rgba(0,0,0,0.1); /* 仅在上下添加阴影 */
    margin-bottom: 20px; 
    padding: 20px; 
}

.loading {
    text-align: center;
    padding: 20px;
}

.top-button {
    /* display: flex;
    flex-direction: column; */
    align-items: center;
    /* flex-grow: 1; */
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 3px;
    margin-right: 3px;
    cursor: pointer;
    background:linear-gradient(90deg, #7398ff, #4f77fb);
    transition: background-color 0.3s;
    color: white;
    border: 1px solid #ccc;
    /* 圆角边框 */
    border-radius: 6px;
    margin-right: 10px;
    font-size: 16px;
    cursor: pointer;
}

</style>